<template>
  <div
    class="relative flex h-[26px] w-[26px] shrink-0 items-center justify-center rounded-full bg-white"
  >
    <div class="flex h-3.5 w-3.5 items-center justify-center">
      <slot />
    </div>
    <div
      class="absolute top-0 h-full w-full rounded-full border-2 border-black/10"
    />
  </div>
</template>
